// Variable
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #ffffff;
$gray-50: #f4f4f4;
$gray-100: #e9e9e9;
$gray-200: #d3d2d2;
$gray-300: #bdbcbc;
$gray-400: #a7a5a5;
$gray-500: #918f8f;
$gray-600: #747272;
$gray-700: #575656;
$gray-800: #3a3939;
$gray-900: #1d1d1d;
$black: #000000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "50": $gray-50,
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
);
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start labcorp-color-variables
$azure: #3a5ce9; //blue & primary
$cerulean: #2998e3; //secondary
$turquoise: #4cd5f7; //cyan
$navy: #1a2188; //indigo & alt-primary
$lavender: #6c2fac; //purple & info
$salmon: #f7758c; //pink
$seafoam: #b4f6f5; //teal
$green: #0d820d; //success
$yellow: #f5bc0f; //warning
$orange: #e36816; //orange
$red: #c5203c; //danger

$charcoal: #231f20;
$charcoal-75: #5a5758;
$charcoal-65: #706d6e;
$charcoal-50: #918f8f;
$charcoal-25: #c8c7c7;
// scss-docs-end labcorp-color-variables

// scss-docs-start color-variables
$blue: $azure;
$indigo: $navy;
$purple: $lavender;
$pink: $salmon;
$red: $red;
$orange: $orange;
$yellow: $yellow;
$green: $green;
$teal: $seafoam;
$cyan: $turquoise;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "black": $black,
  "white": $white,
  "gray": $gray-500,
  "gray-dark": $gray-900,
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $azure;
$alt-primary: $navy;
$secondary: $cerulean;
$success: $green;
$warning: $yellow;
$danger: $red;
$info: $lavender;
$light: $gray-100;
$dark: $gray-800;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
// Used for alerts, buttons, badges, and table colors
$theme-colors: (
  "primary": $primary,
  "alt-primary": $alt-primary,
  "secondary": $secondary,
  "success": $success,
  "warning": $warning,
  "danger": $danger,
  "info": $info,
  "light": $light,
  "dark": $dark,
);
// scss-docs-end theme-colors-map

// fusv-disable
$primary-50: tint-color($primary, 90%);
$primary-100: tint-color($primary, 80%);
$primary-200: tint-color($primary, 60%);
$primary-300: tint-color($primary, 40%);
$primary-400: tint-color($primary, 20%);
$primary-500: $primary;
$primary-600: shade-color($primary, 20%);
$primary-700: shade-color($primary, 40%);
$primary-800: shade-color($primary, 60%);
$primary-900: shade-color($primary, 80%);

$alt-primary-50: tint-color($alt-primary, 90%);
$alt-primary-100: tint-color($alt-primary, 80%);
$alt-primary-200: tint-color($alt-primary, 60%);
$alt-primary-300: tint-color($alt-primary, 40%);
$alt-primary-400: tint-color($alt-primary, 20%);
$alt-primary-500: $alt-primary;
$alt-primary-600: shade-color($alt-primary, 20%);
$alt-primary-700: shade-color($alt-primary, 40%);
$alt-primary-800: shade-color($alt-primary, 60%);
$alt-primary-900: shade-color($alt-primary, 80%);

$secondary-50: tint-color($secondary, 90%);
$secondary-100: tint-color($secondary, 80%);
$secondary-200: tint-color($secondary, 60%);
$secondary-300: tint-color($secondary, 40%);
$secondary-400: tint-color($secondary, 20%);
$secondary-500: $secondary;
$secondary-600: shade-color($secondary, 20%);
$secondary-700: shade-color($secondary, 40%);
$secondary-800: shade-color($secondary, 60%);
$secondary-900: shade-color($secondary, 80%);

$success-50: tint-color($success, 90%);
$success-100: tint-color($success, 80%);
$success-200: tint-color($success, 60%);
$success-300: tint-color($success, 40%);
$success-400: tint-color($success, 20%);
$success-500: $success;
$success-600: shade-color($success, 20%);
$success-700: shade-color($success, 40%);
$success-800: shade-color($success, 60%);
$success-900: shade-color($success, 80%);

$warning-50: tint-color($warning, 90%);
$warning-100: tint-color($warning, 80%);
$warning-200: tint-color($warning, 60%);
$warning-300: tint-color($warning, 40%);
$warning-400: tint-color($warning, 20%);
$warning-500: $warning;
$warning-600: shade-color($warning, 20%);
$warning-700: shade-color($warning, 40%);
$warning-800: shade-color($warning, 60%);
$warning-900: shade-color($warning, 80%);

$orange-50: tint-color($orange, 90%);
$orange-100: tint-color($orange, 80%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 80%);

$danger-50: tint-color($danger, 90%);
$danger-100: tint-color($danger, 80%);
$danger-200: tint-color($danger, 60%);
$danger-300: tint-color($danger, 40%);
$danger-400: tint-color($danger, 20%);
$danger-500: $danger;
$danger-600: shade-color($danger, 20%);
$danger-700: shade-color($danger, 40%);
$danger-800: shade-color($danger, 60%);
$danger-900: shade-color($danger, 80%);

$info-50: tint-color($info, 90%);
$info-100: tint-color($info, 80%);
$info-200: tint-color($info, 60%);
$info-300: tint-color($info, 40%);
$info-400: tint-color($info, 20%);
$info-500: $info;
$info-600: shade-color($info, 20%);
$info-700: shade-color($info, 40%);
$info-800: shade-color($info, 60%);
$info-900: shade-color($info, 80%);

$navy-50: tint-color($navy, 90%);
$navy-100: tint-color($navy, 80%);
$navy-200: tint-color($navy, 60%);
$navy-300: tint-color($navy, 40%);
$navy-400: tint-color($navy, 20%);
$navy-500: $navy;
$navy-600: shade-color($navy, 20%);
$navy-700: shade-color($navy, 40%);
$navy-800: shade-color($navy, 60%);
$navy-900: shade-color($navy, 80%);

$turquoise-50: tint-color($turquoise, 90%);
$turquoise-100: tint-color($turquoise, 80%);
$turquoise-200: tint-color($turquoise, 60%);
$turquoise-300: tint-color($turquoise, 40%);
$turquoise-400: tint-color($turquoise, 20%);
$turquoise-500: $turquoise;
$turquoise-600: shade-color($turquoise, 20%);
$turquoise-700: shade-color($turquoise, 40%);
$turquoise-800: shade-color($turquoise, 60%);
$turquoise-900: shade-color($turquoise, 80%);

$salmon-50: tint-color($salmon, 90%);
$salmon-100: tint-color($salmon, 80%);
$salmon-200: tint-color($salmon, 60%);
$salmon-300: tint-color($salmon, 40%);
$salmon-400: tint-color($salmon, 20%);
$salmon-500: $salmon;
$salmon-600: shade-color($salmon, 20%);
$salmon-700: shade-color($salmon, 40%);
$salmon-800: shade-color($salmon, 60%);
$salmon-900: shade-color($salmon, 80%);

$seafoam-50: tint-color($seafoam, 90%);
$seafoam-100: tint-color($seafoam, 80%);
$seafoam-200: tint-color($seafoam, 60%);
$seafoam-300: tint-color($seafoam, 40%);
$seafoam-400: tint-color($seafoam, 20%);
$seafoam-500: $seafoam;
$seafoam-600: shade-color($seafoam, 20%);
$seafoam-700: shade-color($seafoam, 40%);
$seafoam-800: shade-color($seafoam, 60%);
$seafoam-900: shade-color($seafoam, 80%);

$primaries: (
  "primary-50": $primary-50,
  "primary-100": $primary-100,
  "primary-200": $primary-200,
  "primary-300": $primary-300,
  "primary-400": $primary-400,
  "primary-500": $primary-500,
  "primary-600": $primary-600,
  "primary-700": $primary-700,
  "primary-800": $primary-800,
  "primary-900": $primary-900,
);

$alt-primaries: (
  "alt-primary-50": $alt-primary-50,
  "alt-primary-100": $alt-primary-100,
  "alt-primary-200": $alt-primary-200,
  "alt-primary-300": $alt-primary-300,
  "alt-primary-400": $alt-primary-400,
  "alt-primary-500": $alt-primary-500,
  "alt-primary-600": $alt-primary-600,
  "alt-primary-700": $alt-primary-700,
  "alt-primary-800": $alt-primary-800,
  "alt-primary-900": $alt-primary-900,
);

$secondaries: (
  "secondary-50": $secondary-50,
  "secondary-100": $secondary-100,
  "secondary-200": $secondary-200,
  "secondary-300": $secondary-300,
  "secondary-400": $secondary-400,
  "secondary-500": $secondary-500,
  "secondary-600": $secondary-600,
  "secondary-700": $secondary-700,
  "secondary-800": $secondary-800,
  "secondary-900": $secondary-900,
);

$successes: (
  "success-50": $success-50,
  "success-100": $success-100,
  "success-200": $success-200,
  "success-300": $success-300,
  "success-400": $success-400,
  "success-500": $success-500,
  "success-600": $success-600,
  "success-700": $success-700,
  "success-800": $success-800,
  "success-900": $success-900,
);

$warnings: (
  "warning-50": $warning-50,
  "warning-100": $warning-100,
  "warning-200": $warning-200,
  "warning-300": $warning-300,
  "warning-400": $warning-400,
  "warning-500": $warning-500,
  "warning-600": $warning-600,
  "warning-700": $warning-700,
  "warning-800": $warning-800,
  "warning-900": $warning-900,
);

$oranges: (
  "orange-50": $orange-50,
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900,
);

$dangers: (
  "danger-50": $danger-50,
  "danger-100": $danger-100,
  "danger-200": $danger-200,
  "danger-300": $danger-300,
  "danger-400": $danger-400,
  "danger-500": $danger-500,
  "danger-600": $danger-600,
  "danger-700": $danger-700,
  "danger-800": $danger-800,
  "danger-900": $danger-900,
);

$infos: (
  "info-50": $info-50,
  "info-100": $info-100,
  "info-200": $info-200,
  "info-300": $info-300,
  "info-400": $info-400,
  "info-500": $info-500,
  "info-600": $info-600,
  "info-700": $info-700,
  "info-800": $info-800,
  "info-900": $info-900,
);

$charcoals: (
  "charcoal": $charcoal,
  "charcoal-75": $charcoal-75,
  "charcoal-65": $charcoal-65,
  "charcoal-50": $charcoal-50,
  "charcoal-25": $charcoal-25,
);

$navies: (
  "navy-50": $navy-50,
  "navy-100": $navy-100,
  "navy-200": $navy-200,
  "navy-300": $navy-300,
  "navy-400": $navy-400,
  "navy-500": $navy-500,
  "navy-600": $navy-600,
  "navy-700": $navy-700,
  "navy-800": $navy-800,
  "navy-900": $navy-900,
);

$turquoises: (
  "turquoise-50": $turquoise-50,
  "turquoise-100": $turquoise-100,
  "turquoise-200": $turquoise-200,
  "turquoise-300": $turquoise-300,
  "turquoise-400": $turquoise-400,
  "turquoise-500": $turquoise-500,
  "turquoise-600": $turquoise-600,
  "turquoise-700": $turquoise-700,
  "turquoise-800": $turquoise-800,
  "turquoise-900": $turquoise-900,
);

$salmons: (
  "salmon-50": $salmon-50,
  "salmon-100": $salmon-100,
  "salmon-200": $salmon-200,
  "salmon-300": $salmon-300,
  "salmon-400": $salmon-400,
  "salmon-500": $salmon-500,
  "salmon-600": $salmon-600,
  "salmon-700": $salmon-700,
  "salmon-800": $salmon-800,
  "salmon-900": $salmon-900,
);

$seafoams: (
  "seafoam-50": $seafoam-50,
  "seafoam-100": $seafoam-100,
  "seafoam-200": $seafoam-200,
  "seafoam-300": $seafoam-300,
  "seafoam-400": $seafoam-400,
  "seafoam-500": $seafoam-500,
  "seafoam-600": $seafoam-600,
  "seafoam-700": $seafoam-700,
  "seafoam-800": $seafoam-800,
  "seafoam-900": $seafoam-900,
);
// fusv-enable

// scss-docs-start custom-colors-map
$custom-colors: (
  "primary-50": $primary-50,
  "primary-100": $primary-100,
  "primary-200": $primary-200,
  "primary-300": $primary-300,
  "primary-400": $primary-400,
  "primary-500": $primary-500,
  "primary-600": $primary-600,
  "primary-700": $primary-700,
  "primary-800": $primary-800,
  "primary-900": $primary-900,
  "alt-primary-50": $alt-primary-50,
  "alt-primary-100": $alt-primary-100,
  "alt-primary-200": $alt-primary-200,
  "alt-primary-300": $alt-primary-300,
  "alt-primary-400": $alt-primary-400,
  "alt-primary-500": $alt-primary-500,
  "alt-primary-600": $alt-primary-600,
  "alt-primary-700": $alt-primary-700,
  "alt-primary-800": $alt-primary-800,
  "alt-primary-900": $alt-primary-900,
  "secondary-50": $secondary-50,
  "secondary-100": $secondary-100,
  "secondary-200": $secondary-200,
  "secondary-300": $secondary-300,
  "secondary-400": $secondary-400,
  "secondary-500": $secondary-500,
  "secondary-600": $secondary-600,
  "secondary-700": $secondary-700,
  "secondary-800": $secondary-800,
  "secondary-900": $secondary-900,
  "success-50": $success-50,
  "success-100": $success-100,
  "success-200": $success-200,
  "success-300": $success-300,
  "success-400": $success-400,
  "success-500": $success-500,
  "success-600": $success-600,
  "success-700": $success-700,
  "success-800": $success-800,
  "success-900": $success-900,
  "warning-50": $warning-50,
  "warning-100": $warning-100,
  "warning-200": $warning-200,
  "warning-300": $warning-300,
  "warning-400": $warning-400,
  "warning-500": $warning-500,
  "warning-600": $warning-600,
  "warning-700": $warning-700,
  "warning-800": $warning-800,
  "warning-900": $warning-900,
  "danger-50": $danger-50,
  "danger-100": $danger-100,
  "danger-200": $danger-200,
  "danger-300": $danger-300,
  "danger-400": $danger-400,
  "danger-500": $danger-500,
  "danger-600": $danger-600,
  "danger-700": $danger-700,
  "danger-800": $danger-800,
  "danger-900": $danger-900,
  "info-50": $info-50,
  "info-100": $info-100,
  "info-200": $info-200,
  "info-300": $info-300,
  "info-400": $info-400,
  "info-500": $info-500,
  "info-600": $info-600,
  "info-700": $info-700,
  "info-800": $info-800,
  "info-900": $info-900,
  "white": $white,
  "gray": $gray-500,
  "gray-50": $gray-50,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "black": $black,
  "charcoal": $charcoal,
  "charcoal-75": $charcoal-75,
  "charcoal-65": $charcoal-65,
  "charcoal-50": $charcoal-50,
  "charcoal-25": $charcoal-25,
  "navy": $navy,
  "navy-50": $navy-50,
  "navy-100": $navy-100,
  "navy-200": $navy-200,
  "navy-300": $navy-300,
  "navy-400": $navy-400,
  "navy-500": $navy-500,
  "navy-600": $navy-600,
  "navy-700": $navy-700,
  "navy-800": $navy-800,
  "navy-900": $navy-900,
  "orange": $orange,
  "orange-50": $orange-50,
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900,
  "turquoise": $turquoise,
  "turquoise-50": $turquoise-50,
  "turquoise-100": $turquoise-100,
  "turquoise-200": $turquoise-200,
  "turquoise-300": $turquoise-300,
  "turquoise-400": $turquoise-400,
  "turquoise-500": $turquoise-500,
  "turquoise-600": $turquoise-600,
  "turquoise-700": $turquoise-700,
  "turquoise-800": $turquoise-800,
  "turquoise-900": $turquoise-900,
  "salmon": $salmon,
  "salmon-50": $salmon-50,
  "salmon-100": $salmon-100,
  "salmon-200": $salmon-200,
  "salmon-300": $salmon-300,
  "salmon-400": $salmon-400,
  "salmon-500": $salmon-500,
  "salmon-600": $salmon-600,
  "salmon-700": $salmon-700,
  "salmon-800": $salmon-800,
  "salmon-900": $salmon-900,
  "seafoam": $seafoam,
  "seafoam-50": $seafoam-50,
  "seafoam-100": $seafoam-100,
  "seafoam-200": $seafoam-200,
  "seafoam-300": $seafoam-300,
  "seafoam-400": $seafoam-400,
  "seafoam-500": $seafoam-500,
  "seafoam-600": $seafoam-600,
  "seafoam-700": $seafoam-700,
  "seafoam-800": $seafoam-800,
  "seafoam-900": $seafoam-900,
);
// scss-docs-end custom-colors-map

// All Colors
// Used for backgrounds, borders, and text
$all-colors: map-merge($theme-colors, $custom-colors);

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $charcoal;
$color-contrast-light: $white;

// scss-docs-start theme-text-variables
$light-text-emphasis:     $gray-700;
$dark-text-emphasis:      $gray-900;
// scss-docs-end theme-text-variables

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false;
$enable-validation-icons: false;
$enable-negative-margins: true;

// Prefix for :root CSS variables

$variable-prefix: bs-; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix;

// Gradient

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,    //4px
  2: $spacer * 0.5,     //8px
  3: $spacer * 0.75,    //12px
  4: $spacer,           //16px
  5: $spacer * 1.5,     //24px
  6: $spacer * 2,       //32px
  7: $spacer * 3,       //48px
  8: $spacer * 4,       //64px
  9: $spacer * 6,       //96px
  10: $spacer * 8,      //128px
  11: $spacer * 12,     //192px
  12: $spacer * 16,     //256px
  13: $spacer * 21.75,  //348px
  14: $spacer * 32,     //512px
);
// scss-docs-end spacer-variables-maps$okta-border-style

// Position
//
// Define the edge positioning anchors of the position utilities.

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $charcoal;

$body-secondary-bg:         $gray-200;
$body-tertiary-bg:          $gray-100;

// Links
//
// Style anchor elements.

$link-decoration-none: none;
$link-shade-percentage: 40%;
$link-hover-color: $navy;

// Icon links

// Paragraphs
//
// Style p element.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: ($spacer * 2);

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width: 1px;
$border-color: $charcoal-50;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: ($spacer * 0.75); //12px
$border-radius-xs: ($spacer * 0.25); //4px
$border-radius-sm: ($spacer * 0.5); //8px
$border-radius-lg: $spacer; //16px
$border-radius-xl: ($spacer * 1.5); //24px
$border-radius-xxl: ($spacer * 2); //32px
$border-radius-pill: 50rem;
// scss-docs-end border-radius-variables
// fusv-disable
$border-radius-2xl: $border-radius-xxl !default; // Deprecated in v5.3.0
// fusv-enable

// scss-docs-start box-shadow-variables
$box-shadow-hover: 0 0 .5rem rgba($black, .5);
// scss-docs-end box-shadow-variables

// scss-docs-start focus-ring-variables
$focus-ring-width:      .25rem;
$focus-ring-opacity:    1;
$focus-ring-blur:       0;
// scss-docs-end focus-ring-variables

$component-active-color: $white;
$component-active-bg: $primary;

// scss-docs-start caret-variables
$caret-width: 0.3em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;
// scss-docs-end caret-variables

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Source Sans Pro", sans-serif;
// stylelint-enable value-keyword-case

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-base: 1rem; //16px
$font-size-xs: $font-size-base * 0.75;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lighter: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$line-height-base: 1.5;
$line-height-sm: 1.285715;
$line-height-lg: 1.4;

$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.875;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;
$h6-font-size: $font-size-base;
// scss-docs-end font-variables

// scss-docs-start headings-variables
$headings-line-height: 1.3;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5.5rem,
  2: 5rem,
  3: 4.5rem,
  4: 4rem,
  5: 3.5rem,
  6: 2.75rem,
);

$display-line-height: 1.5;
// scss-docs-end display-headings

// fusv-disable
$text-muted: $charcoal-65; // Deprecated in 5.3.0
// fusv-enable

$hr-color: $gray-300;

$hr-opacity: 1;

$mark-padding: 0;
$mark-color: $white;
$mark-bg: $navy;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: 0.75rem;
$table-cell-padding-x: 0.75rem;
$table-cell-padding-y-sm: 0.25rem;
$table-cell-padding-x-sm: 0.5rem;

$table-color:                 var(--#{$prefix}emphasis-color);
$table-bg:                    transparent;
// scss-docs-end table-variables

$table-light-striped-color: $table-color;
$table-light-striped-bg-factor: 0.75;
$table-light-striped-bg: rgba(var(--#{$prefix}body-bg-rgb), $table-light-striped-bg-factor);

$table-active-color: $table-color;
$table-active-bg-factor: 0.15;
$table-active-bg: rgba($turquoise, $table-active-bg-factor);

$table-hover-bg-factor: 0;

$table-border-factor: 0.45;
$table-hover-bg: inherit;

$table-bg-scale: -90%;
// scss-docs-end table-variables

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.6875rem;
$input-btn-padding-x: 1rem;
$input-btn-line-height: $line-height-base;

$input-btn-padding-y-sm: 0.375rem;
$input-btn-padding-x-sm: 0.75rem;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 1.0625rem;
$input-btn-padding-x-lg: 1.25rem;
$input-btn-line-height-lg: 1.166666666666667;
$input-btn-font-size-lg: 1.5rem;
$input-btn-font-weight-lg: $font-weight-semi-bold;

$input-btn-border-width: $border-width;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-x: 1.5rem;
$btn-font-weight: $font-weight-bold;

$btn-font-weight-sm: $font-weight-bold;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-font-weight-lg: $font-weight-semi-bold;
$btn-line-height-lg: $input-btn-line-height-lg;
$btn-padding-x-lg: 2rem;

$btn-disabled-opacity: 0.7;

$btn-link-disabled-color: $gray-700;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 1.5rem;
$btn-border-radius-lg: 2rem;
$btn-border-radius-sm: 1rem;

$btn-min-width: $spacer * 8;

$btn-hover-bg-shade-amount: 20%;
$btn-hover-bg-tint-amount: 20%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 20%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 20%;
$btn-active-border-tint-amount: 20%;
// scss-docs-end btn-variables

// Forms

// scss-docs-start form-text-variables
$form-text-line-height: $line-height-sm;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-font-weight: $font-weight-semi-bold;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-x: $input-btn-padding-x;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-padding-x-lg: $input-btn-padding-x-lg;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-disabled-color: $charcoal-75;
$input-disabled-bg: var(--#{$prefix}tertiary-bg); //$gray-100

$input-border-color: $gray-500;
$input-border-width: $input-btn-border-width;

$input-placeholder-color: $charcoal-65;

$input-height-border: $input-border-width * 2;

$input-height-sm: add(
  $input-btn-line-height-sm * 1em,
  add($input-padding-y-sm * 2, $input-height-border, false)
);
$input-height-lg: add(
  $input-btn-line-height-lg * 1em,
  add($input-padding-y-lg * 2, $input-height-border, false)
);
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width: 1.125em;
$form-radio-input-width: 1.24em;

$form-check-input-border: 2px solid var(--#{$prefix}body-color);
$form-check-input-border-radius: 3px;
$form-check-input-focus-border: var(--#{$prefix}body-color);

$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='18' width='18' viewBox='3 3 18 18' fill='#{$form-check-input-checked-color}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M9 16.2l-3.5-3.5c-.39-.39-1.01-.39-1.4 0-.39.39-.39 1.01 0 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0L9 16.2z'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2.5' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity: 0.65;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color: var(--#{$prefix}body-color);
$form-switch-height: 1.125em;
$form-switch-margin-top: 0.25rem;
$form-switch-checked-color:       $component-active-color;
$form-switch-checked-bg-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-focus-color: $form-check-input-focus-border;
$form-switch-bg-image-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$danger}'/></svg>");
$form-switch-bg-image-invalid-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$white}'/></svg>");
// scss-docs-end form-switch-variables

// scss-docs-start input-group-variables
$input-group-custom-icon-size: calc(
  (#{$input-padding-x} / 2) + 24px + #{$input-padding-x}
);
$input-group-custom-icon-size-sm: calc(
  (#{$input-padding-x-sm} / 2) + 18px + #{$input-padding-x-sm}
);
$input-group-custom-icon-size-lg: calc(
  (#{$input-padding-x-lg} / 2) + 28px + #{$input-padding-x-lg}
);
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-disabled-color: $input-disabled-color;
$form-select-disabled-bg: $input-disabled-bg;
$form-select-bg-size: 1.5em;
$form-select-indicator-color: $body-color;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='28px' viewBox='0 0 24 24' width='28px' fill='#{$form-select-indicator-color}'><path d='M24 24H0V0h24v24z' fill='none' opacity='.87'/><path d='M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z'/></svg>");
// scss-docs-end form-select-variables

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-line-height: $form-text-line-height;
$form-feedback-valid-color: $success;
$form-feedback-warning-color: $warning-600;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-warning-color: $form-feedback-warning-color;
$form-feedback-icon-warning: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-warning-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-warning-color}' stroke='none'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid,
  ),
  "warning": (
    "color": $form-feedback-warning-color,
    "icon": $form-feedback-icon-warning,
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid,
  ),
);
// scss-docs-end form-validation-states

// Z-index master list

// Navs

// scss-docs-start nav-variables
$nav-link-padding-y: 0.75rem;
$nav-link-disabled-color: $charcoal-65;

$nav-tabs-border-color: var(--#{$prefix}border-color);
$nav-tabs-link-active-color: var(--#{$prefix}body-color);
$nav-tabs-link-active-bg: var(--#{$prefix}body-bg);
$nav-tabs-link-active-border-color: $border-color $border-color $nav-tabs-link-active-bg;
$nav-tabs-link-active-font-weight: $font-weight-bold;
// scss-docs-end nav-variables

// Labcorp Sidebar
$sidebar-width: $spacer * 18;
$sidebar-bg-color: var(--#{$prefix}tertiary-bg);
$sidebar-border-color: var(--#{$prefix}border-color);
$sidebar-item-padding-y: 1rem;
$sidebar-item-padding-x: 2rem;
$sidebar-item-color: var(--#{$prefix}body-color);
$sidebar-item-hover-color: var(--#{$prefix}body-color);
$sidebar-item-hover-bg: var(--#{$prefix}body-bg);
$sidebar-item-hover-icon-color: $primary;
$sidebar-item-active-icon-color: $sidebar-item-hover-icon-color;
$sidebar-item-active-color: $sidebar-item-hover-color;
$sidebar-item-active-bg: $sidebar-item-hover-bg;
$sidebar-item-border-color: $primary;
$sidebar-subnav-bg: var(--#{$prefix}body-bg);
$sidebar-subnav-item-padding-y: .5rem;
$sidebar-subnav-item-padding-left: 4.5rem;
$sidebar-subnav-item-padding-right: 1.5rem;
$sidebar-dark-bg-color: $navy;
$sidebar-dark-border-color: $navy;
$sidebar-dark-item-color: $white;
$sidebar-dark-item-hover-color: $white;
$sidebar-dark-item-hover-bg: $navy-700;
$sidebar-dark-item-hover-icon-color: $white;
$sidebar-dark-item-active-icon-color: $sidebar-dark-item-hover-icon-color;
$sidebar-dark-item-active-color: $sidebar-dark-item-hover-color;
$sidebar-dark-item-active-bg: $sidebar-dark-item-hover-bg;
$sidebar-dark-item-border-color: $white;
$sidebar-dark-subnav-bg: $navy-700;

// Navbar

// scss-docs-start navbar-variables
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;

$navbar-light-color: rgba($primary, 0.904);
$navbar-light-hover-color: rgba($primary, 0.95);
$navbar-light-active-color: $primary;
$navbar-light-disabled-color: rgba($primary, 0.765);
$navbar-light-toggler-border-color: rgba($primary, 0.1);

$navbar-dark-color: rgba($white, 0.87);
$navbar-dark-hover-color: rgba($white, 0.97);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.7);
$navbar-dark-toggler-border-color: rgba($white, 0.1);
$navbar-dark-focus-color: 0 0 $focus-ring-blur $focus-ring-width $white;

// scss-docs-end navbar-variables

// Dropdowns
//
// Dropdown menu container and contents.

// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y: 0.688rem;
$pagination-padding-x: 1.24rem;
$pagination-padding-y-sm: 0.282rem;
$pagination-padding-x-sm: 0.75rem;
$pagination-padding-y-lg: 1rem;
$pagination-padding-x-lg: 1.75rem;

$pagination-bg: transparent;
$pagination-border-color: transparent;

$pagination-focus-bg: transparent;

$pagination-hover-bg: transparent;
$pagination-hover-border-color: transparent;

$pagination-disabled-color: $charcoal-65;
$pagination-disabled-border-color: transparent;
// scss-docs-end pagination-variables

// Placeholders

// Cards

// scss-docs-start card-variables
$card-border-color: $border-color;
// scss-docs-end card-variables

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y:                     1rem;
$accordion-padding-x:                     1rem;
$accordion-color:                         var(--#{$prefix}body-color);
$accordion-border-active-color:           $primary;

$accordion-button-color:                  $accordion-color;
$accordion-button-active-bg:              var(--#{$prefix}body-bg);

$accordion-button-focus-border-color:     var(--#{$prefix}primary);
$accordion-button-active-color:           $accordion-color;

$accordion-icon-width:                    1.5rem;
$accordion-icon-color:                    $accordion-color;
$accordion-icon-active-color:             $accordion-button-active-color;

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$accordion-icon-color}'><path d='M24 24H0V0h24v24z' fill='none' opacity='.87'/><path d='M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$accordion-button-active-color}'><path d='M24 24H0V0h24v24z' fill='none' opacity='.87'/><path d='M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z'/></svg>");
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-color: $charcoal;
$tooltip-bg: $white;
$tooltip-opacity: 1;
$tooltip-border-color: $border-color;
$tooltip-padding-y: $spacer * 0.5;
$tooltip-padding-x: $spacer * 0.75;

$tooltip-space: $spacer * 0.125;

$tooltip-arrow-color: $white;
// scss-docs-end tooltip-variables

// Popovers

// Toasts

// scss-docs-start toast-variables
$toast-max-width: $spacer * 21.75;
$toast-padding-x: $spacer;
$toast-padding-y: $spacer * 0.75;
$toast-font-size: $font-size-base;
$toast-color: var(--#{$prefix}body-color);
$toast-background-color: var(--#{$prefix}body-bg);
$toast-spacing: 1rem;
$toast-left-border: $spacer * 3;
// scss-docs-end toast-variables

// Badges

// scss-docs-start badge-variables
$badge-font-size: $font-size-xs;
$badge-font-weight: $font-weight-semi-bold;
$badge-padding-y: $spacer * 0.1875;
$badge-padding-x: $spacer * 0.5;
$badge-border-radius: var(--#{$prefix}border-radius-xs);
// scss-docs-end badge-variables

// Modals

// scss-docs-start modal-variables
$modal-inner-padding: $spacer * 2;

$modal-footer-margin-between: $spacer * 1.5;

$modal-title-line-height: 1.2;

$modal-header-border-color: $primary;
$modal-header-border-width: $spacer * 0.5;
$modal-header-padding-y: 1.25rem;
$modal-header-padding-x: $spacer * 2;

$modal-footer-border-width: 0;
// scss-docs-end modal-variables

// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
$alert-padding-y: $spacer * 0.75;
$alert-padding-x: $spacer;
// scss-docs-end alert-variables

// fusv-disable
$alert-bg-scale: 0%;
$alert-border-scale: 0%;
$alert-color-scale: 0%;
// fusv-enable

// Progress bars

// List group

// scss-docs-start list-group-variables
$list-group-border-color: $border-color;
$list-group-border-width: $border-width;

$list-group-item-padding-y: calc(0.75rem - #{$list-group-border-width});
// fusv-disable
$list-group-item-bg-scale: -90%;
$list-group-item-color-scale: 60%;
// fusv-enable

$list-group-disabled-color: $charcoal-65;

$list-group-action-color: $body-color;
// scss-docs-end list-group-variables

// Image thumbnails

// Figures

// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-divider-color: $charcoal-65;
$breadcrumb-active-color: $charcoal-65;
// scss-docs-end breadcrumb-variables

// Carousel

// Spinners

// Close

// scss-docs-start close-variables
$btn-close-width: $spacer * 1.5;
$btn-close-color: $body-color;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$btn-close-color}'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z'/></svg>");
// scss-docs-end close-variables

// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-padding-y: $spacer * 1.25;
$offcanvas-horizontal-width: $spacer * 18;
// scss-docs-end offcanvas-variables

// Code

$code-color: $red;

// Material Icons

$icon-vertical-alignment: middle;

$icon-size: 24px;
$icon-margin-top: -0.125rem;

$icon-sm-size: 18px;
$icon-sm-margin-top: 0;

$icon-lg-size: 28px;
$icon-lg-margin-top: -0.015rem;

// button placement
$icon-button-margin-top: -0.253rem;
$icon-button-sm-margin-top: -0.190429681rem;
$icon-button-lg-margin-top: -0.260742173rem;

// dropdown placement
$icon-dropdown-margin-x: -$spacer * 0.25;

// form feedback
$icon-form-feedback-sm-margin-top: $icon-button-sm-margin-top;

// chips
$chip-padding-x: 0.5rem;
$chip-padding-y: 0.125rem;
$chip-color: var(--#{$prefix}body-color);
$chip-bg-color: var(--#{$prefix}body-bg);
$chip-border-color: var(--#{$prefix}body-color);
$chip-border-width: $input-btn-border-width;
$chip-border-radius: $border-radius-pill;
$chip-font-size: $font-size-sm;
$chip-line-height: $btn-line-height-sm;
$chip-font-weight: $font-weight-normal;
$chip-max-width: $spacer * 16;
