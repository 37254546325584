@import "../../labcorp/required";
$material-icons-font-path: 'material-icons/iconfont/' !default;
@import "material-icons/iconfont/round.scss";

.material-icons-round {
  font-size: $icon-size;
  // Position Fix
  margin-top: $icon-margin-top;
  vertical-align: $icon-vertical-alignment;
  position: relative;

  // Icon sizes
  &.icon-sm {
    font-size: $icon-sm-size;
    margin-top: $icon-sm-margin-top;
    transform: rotate(0.03deg);
  }

  &.icon-lg {
    font-size: $icon-lg-size;
    margin-top: $icon-lg-margin-top;
  }
}

.btn {
  .material-icons-round {
    margin-top: $icon-button-margin-top;
  }
  &.btn-sm {
    .material-icons-round {
      margin-top: $icon-button-sm-margin-top;
    }
  }
  &.btn-lg {
    .material-icons-round {
      margin-top: $icon-button-lg-margin-top;
    }
  }
}

.form-text,
.invalid-feedback,
.valid-feedback,
.warning-feedback {
  .material-icons-round {
    &.icon-sm {
      margin-top: $icon-form-feedback-sm-margin-top;
    }
  }
}
