@import "../../../labcorp/required";

$datepicker-day-size: 2rem;
$datepicker-selected-bg: var(--#{$prefix}primary-rgb);
$datepicker-selected-padding: .3rem 2rem .3rem .5rem;
$datepicker-selected-color: var(--#{$prefix}body-color);
$datepicker-border-radius: $border-radius-sm;

ngb-datepicker {
  // Container
  //////////////////////////////////////////////////
  border: 1px solid var(--#{$prefix}body-color) !important;
  border-radius: $border-radius !important; //Custom
  display: inline-block;
  padding: ($spacer * 0.5) !important; //Custom
  font-size: $font-size-base !important; //Custom

  &-month {
    pointer-events: auto;
  }

  &.dropdown-menu {
    @extend .shadow !optional; //Custom
    padding: 0;
  }

  // Header
  //////////////////////////////////////////////////
  .ngb-dp {
    &-body {
      z-index: 1050;
    }

    &-header {
      background-color: var(--#{$prefix}body-bg);
      padding-top: 0.25rem;
    }

    &-months {
      display: flex;
    }

    &-month {
      pointer-events: none;

      &-name {
        height: $datepicker-day-size;
        line-height: $datepicker-day-size;
        text-align: center;
        background-color: var(--#{$prefix}body-bg);
      }

      & + & {
        .ngb-dp-month-name,
        .ngb-dp-week {
          padding-left: 1rem;
        }
      }

      &:last-child .ngb-dp-week {
        padding-right: 0.25rem;
      }

      &:first-child .ngb-dp-week {
        padding-left: 0.25rem;
      }

      .ngb-dp-week:last-child {
        padding-bottom: 0.25rem;
      }
    }

    &-weekday,
    &-week-number {
      line-height: $datepicker-day-size;
      text-align: center;
      font-style: normal;
    }

    &-weekday {
      color: $body-color;
    }

    &-week {
      display: flex;
    }

    &-weekdays {
      border-bottom: none;
      background-color: var(--#{$prefix}body-bg);;
    }

    &-day,
    &-weekday,
    &-week-number {
      width: $datepicker-day-size;
      height: $datepicker-day-size;

      &:focus {
        outline: 3px solid transparent; //Custom
        box-shadow: $btn-focus-box-shadow; //Custom
      }
    }

    &-day {
      cursor: pointer;

      &.disabled,
      &.hidden {
        cursor: default;
        pointer-events: none;
      }

      &[tabindex="0"] {
        z-index: 1;
      }
    }

    &-navigation-chevron {
      border-style: solid;
      border-width: 0.2em 0.2em 0 0;
      display: inline-block;
      width: 0.75em;
      height: 0.75em;
      margin-left: 0.25em;
      margin-right: 0.15em;
      transform: rotate(-135deg);
    }

    .right &-navigation-chevron {
      transform: rotate(45deg);
      margin-left: 0.15em;
      margin-right: 0.25em;
    }

    &-arrow {
      display: flex;
      flex: 1 1 auto;
      padding-right: 0;
      padding-left: 0;
      margin: 0;
      width: $datepicker-day-size;
      height: $datepicker-day-size;

      &.right {
        justify-content: flex-end;
      }
    }

    &-arrow-btn {
      padding: 0 0.25rem;
      margin: 0 0.5rem;
      border: none;
      background-color: transparent;
      z-index: 1;

      // IE workaround, as outline-style: auto doesn't work
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &:focus {
          outline-style: solid;
        }
      }
    }

    &-month-name {
      font-size: larger;
      height: $datepicker-day-size;
      line-height: $datepicker-day-size;
      text-align: center;
    }

    &-navigation-select {
      display: flex;
      flex: 1 1 9rem;
    }
  }

  // Navigation
  //////////////////////////////////////////////////
  ngb-datepicker-navigation-select > .form-select {
    flex: 1 1 auto;
    padding: $datepicker-selected-padding;
    font-size: 0.875rem;
    height: 2rem;
    border-radius: $datepicker-border-radius;

    + .form-select {
      margin-left: ($spacer * 0.5) !important; //Custom
    }

    &:focus {
      z-index: 1;
    }

    // IE11, Edge: Fix arrow icon disappears on focus
    &::-ms-value {
      background-color: transparent !important;
    }
  }

  // Day
  //////////////////////////////////////////////////
  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    height: $datepicker-day-size;
    width: $datepicker-day-size;
    border-radius: $datepicker-border-radius;
    display: block;
  }

  .ngb-dp-day {
    .custom-day,
    [ngbDatepickerDayView] {
      display: block;
      text-align: center;
      height: $datepicker-day-size;
      width: $datepicker-day-size;
      line-height: calc(#{$datepicker-day-size} - 2px);
      border: 1px solid transparent;

      &:hover,
      &:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        border: 1px solid $primary;
      }

      &.outside {
        opacity: 1;
      }
    }
    &.disabled {
      opacity: 0.5;
      .text-muted {
        color: $body-color !important;
      }
    }

    [ngbDatepickerDayView] {
      &:hover,
      &:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        background-color: transparent;
      }

      &.bg-primary {
        font-weight: $font-weight-bold !important;
      }
    }
  }

  // Today
  .ngb-dp-today {
    background-color: rgba($datepicker-selected-bg, 0.15);
    border-radius: $datepicker-border-radius;
  }

  // Selected Day/s
  .custom-day.range {
    color: $white;
    background-color: rgba($datepicker-selected-bg, 1);
    font-weight: $font-weight-bold !important;
  }

  // Range
  .custom-day.range.faded {
    color: $datepicker-selected-color;
    background-color: rgba($datepicker-selected-bg, 0.30);
  }
}

[data-bs-spacing="condensed"] {
  ngb-datepicker-navigation-select > .form-select {
    padding: $datepicker-selected-padding;
  }
}