@import "../../../labcorp/required";

$sort-icon-size: calc(1rem + 2px); //18px
$default-sort-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$body-color}' width='100%' height='100%'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z'/></svg>");
$asc-sort-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$body-color}' height='100%' width='100%'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z'/></svg>");
$desc-sort-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$body-color}' height='100%' width='100%'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/></svg>");
$default-sort-background: escape-svg($default-sort-icon) no-repeat center center;
$asc-sort-background: escape-svg($asc-sort-icon) no-repeat center center;
$desc-sort-background: escape-svg($desc-sort-icon) no-repeat center center;

/* Sortable tables */
th[sortable] {
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  -webkit-user-select: none;

  &:after {
    content: "";
    display: inline-block;
    background: $default-sort-background;
    background-size: $sort-icon-size;
    width: $sort-icon-size;
    height: $sort-icon-size;
    vertical-align: middle;
    opacity: 0.5;
    margin-top: -0.25em;
  }

  &.asc:after {
    background: $asc-sort-background;
    opacity: 1;
  }

  &.desc:after {
    background: $desc-sort-background;
    opacity: 1;
  }
}
