@import "../../labcorp/required";
@import "@ng-select/ng-select/scss/mixins";

$ng-select-highlight: $primary;
$ng-select-primary-text: $input-color;
$ng-select-disabled-text: $input-disabled-color;
$ng-select-disabled-bg: $input-disabled-bg;
$ng-select-border: $input-border-color;
$ng-select-border-radius: $border-radius;
$ng-select-border-radius-condensed: $input-border-radius-condensed;
$ng-select-bg: $input-bg;
$ng-select-active-color: $dropdown-link-active-color;
$ng-select-active-bg: $dropdown-link-active-bg;
$ng-select-hover-bg: $dropdown-link-hover-bg;
$ng-select-bg-position: $form-select-bg-position;
$ng-select-bg-position: $form-select-bg-position-condensed;
$ng-select-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1) !default;
$ng-select-placeholder: $input-placeholder-color;
$ng-select-height: $input-height;
$ng-select-height-condensed: $input-height-condensed;
$ng-value-border-radius: $chip-border-radius;
$ng-select-value-padding-x: $input-btn-padding-x;
$ng-select-value-padding-y: $input-btn-padding-y;
$ng-select-value-padding-x-condensed: $input-btn-padding-x-condensed;
$ng-select-value-padding-y-condensed: $input-btn-padding-y-condensed;
$ng-select-value-font-size: $font-size-sm;
$ng-select-value-bg: $primary;
//$ng-select-arrow: $form-select-indicator;
//$ng-select-custom-bg: escape-svg($form-select-indicator);

.ng-select {
  &.ng-select-opened {
    > .ng-select-container {
      border-color: darken($ng-select-border, 10) $ng-select-border
        lighten($ng-select-border, 5);
      &:hover {
        box-shadow: none;
      }
      .ng-arrow {
        top: -2px;
        border-color: transparent transparent darken($ng-select-border, 20);
        border-width: 0 5px 5px;
        &:hover {
          border-color: transparent transparent darken($ng-select-border, 60);
        }
      }
    }
    &.ng-select-bottom {
      > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &.ng-select-top {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: $ng-select-highlight;
      box-shadow: $ng-select-box-shadow;
    }
  }
  &.ng-select-disabled {
    > .ng-select-container {
      color: $ng-select-disabled-text;
      background-color: $ng-select-disabled-bg;
    }
  }
  .ng-has-value .ng-placeholder {
    display: none;
  }
  .ng-select-container {
    color: $ng-select-primary-text;
    background-color: $input-bg;
    background-image: escape-svg($form-select-indicator);
    background-repeat: no-repeat;
    background-position: $form-select-bg-position;
    background-size: $form-select-bg-size;
    border-radius: $ng-select-border-radius;
    border: 1px solid $ng-select-border;
    min-height: $ng-select-height;
    align-items: center;
    &:hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }
    .ng-value-container {
      align-items: center;
      padding-left: $ng-select-value-padding-x;
      @include rtl {
        padding-right: $ng-select-value-padding-x;
        padding-left: 0;
      }
      .ng-placeholder {
        color: $ng-select-placeholder;
      }
    }
  }
  &.is-invalid {
    .ng-select-container {
      border-color: $form-feedback-invalid-color;
    }
  }
  &.ng-select-single {
    .ng-select-container {
      height: $ng-select-height;
      .ng-value-container {
        .ng-input {
          left: 0;
          padding-left: $ng-select-value-padding-x;
          padding-right: 50px;
          @include rtl {
            padding-right: $ng-select-value-padding-x;
            padding-left: 50px;
          }
        }
      }
    }
  }
  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-disabled-text;
        border: 1px solid lighten($ng-select-border, 10);
        .ng-value-label {
          padding: 0 5px;
        }
      }
    }
    .ng-select-container {
      .ng-value-container {
        padding-top: $spacer * 0.5;
        padding-left: $ng-select-value-padding-x;
        @include rtl {
          padding-right: $input-btn-padding-x;
          padding-left: 0;
        }
        .ng-value {
          font-size: $ng-select-value-font-size;
          line-height: 20px;
          color: $white;
          margin-bottom: $input-btn-padding-y;
          background-color: $ng-select-value-bg;
          border-radius: $ng-value-border-radius;
          margin-right: $input-btn-padding-x * 0.5;
          @include rtl {
            margin-right: 0;
            margin-left: $ng-select-value-padding-x;
          }
          & + .ng-value {
            margin-right: $input-btn-padding-x * 0.5;
          }
          &.ng-value-disabled {
            background-color: $ng-select-disabled-text;
            .ng-value-label {
              padding-left: $ng-select-value-padding-x;
              @include rtl {
                padding-left: 0;
                padding-right: $ng-select-value-padding-x;
              }
            }
          }
          .ng-value-label {
            display: inline-block;
            padding: 2px 8px;
          }
          .ng-value-icon {
            display: inline-block;
            padding: 2px 8px;
            &:hover {
              background-color: darken($ng-select-active-bg, 5);
            }
            &.left {
              border-right: none;
              padding-right: 0px;
              @include rtl {
                border-left: 1px solid darken($ng-select-active-bg, 10);
                border-right: none;
              }
            }
            &.right {
              border-left: none;
              padding-left: 0px;
              @include rtl {
                border-left: 0;
                border-right: 1px solid darken($ng-select-active-bg, 10);
              }
            }
          }
        }
        .ng-input {
          padding: 0 0 7px 0px;
          @include rtl {
            padding: 0 3px 3px 0;
          }
        }
        .ng-placeholder {
          padding-bottom: 5px;
          padding-left: 0;
          @include rtl {
            padding-right: 3px;
            padding-left: 0;
          }
        }
      }
    }
  }
  .ng-clear-wrapper {
    width: 2rem;
    font-size: 1.6rem !important;
    line-height: 1.6rem !important;
    color: darken($ng-select-border, 20);
    .ng-clear {
      font-size: 1.6rem !important;
      line-height: 1.6rem !important;
    }
    &:hover .ng-clear {
      color: #d0021b;
    }
  }
  .ng-spinner-zone {
    padding: 5px 5px 0 0;

    @include rtl {
      padding: 5px 0 0 5px;
    }
  }
  .ng-arrow-wrapper {
    width: 2.5rem;
    padding-right: 5px;
    @include rtl {
      padding-left: $ng-select-value-padding-x;
      padding-right: 0;
    }
    &:hover {
      .ng-arrow {
        //background: $ng-select-arrow;
      }
    }
    .ng-arrow {
      //background: $ng-select-arrow;
    }
  }
}

.ng-dropdown-panel {
  background-color: $ng-select-bg;
  border: 1px solid $ng-select-border;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;
  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
    border-top-color: lighten($ng-select-border, 10);
    margin-top: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-right-radius: $ng-select-border-radius;
          border-bottom-left-radius: $ng-select-border-radius;
        }
      }
    }
  }
  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: $ng-select-border-radius;
    border-top-left-radius: $ng-select-border-radius;
    border-bottom-color: lighten($ng-select-border, 10);
    margin-bottom: -1px;
    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: $ng-select-border-radius;
          border-top-left-radius: $ng-select-border-radius;
        }
      }
    }
  }
  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 5px 7px;
  }
  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-border;
    padding: 5px 7px;
  }
  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      padding: 8px 10px;
      font-weight: 500;
      color: rgba(var(--#{$prefix}body-color-rgb), .6);
      cursor: pointer;
      &.ng-option-disabled {
        cursor: default;
      }
      &.ng-option-marked {
        background-color: $ng-select-hover-bg;
      }
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $ng-select-active-color;
        background-color: $ng-select-active-bg;
        font-weight: 600;
      }
    }
    .ng-option {
      background-color: $ng-select-bg;
      color: $ng-select-primary-text;
      padding: $input-btn-padding-y $input-btn-padding-x;
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $ng-select-active-color;
        background-color: $ng-select-active-bg;
        .ng-option-label {
          font-weight: $font-weight-bold;
        }
      }
      &.ng-option-marked {
        background-color: $ng-select-hover-bg;
        color: $ng-select-primary-text;
      }
      &.ng-option-disabled {
        color: lighten($ng-select-disabled-text, 15);
      }
      &.ng-option-child {
        padding-left: 1.5rem;
        @include rtl {
          padding-right: 1.5rem;
          padding-left: 0;
        }
      }
      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: $ng-select-value-padding-x;
        @include rtl {
          padding-left: $ng-select-value-padding-x;
          padding-right: 0;
        }
      }
    }
  }

  .scroll-host {
    border-bottom-right-radius: $ng-select-border-radius;
    border-bottom-left-radius: $ng-select-border-radius;
  }

  @include rtl {
    direction: rtl;
    text-align: right;
  }
}

[data-bs-spacing="condensed"] {
  .ng-select {
    .ng-select-container {
      border-radius: $ng-select-border-radius-condensed;
      min-height: $ng-select-height-condensed;
      background-position: $form-select-bg-position-condensed;
      .ng-value-container {
        padding-left: $ng-select-value-padding-x-condensed;
        @include rtl {
          padding-right: $ng-select-value-padding-x-condensed;
        }
      }
    }
    &.ng-select-single {
      .ng-select-container {
        height: $ng-select-height-condensed;
        .ng-value-container {
          .ng-input {
            padding-left: $ng-select-value-padding-x-condensed;
            @include rtl {
              padding-right: $ng-select-value-padding-x-condensed;
            }
          }
        }
      }
    }
    &.ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          padding-left: $ng-select-value-padding-x-condensed;
          .ng-value {
            @include rtl {
              margin-left: $ng-select-value-padding-x-condensed;
            }
            &.ng-value-disabled {
              .ng-value-label {
                padding-left: $ng-select-value-padding-x-condensed;
                @include rtl {
                  padding-right: $ng-select-value-padding-x-condensed;
                }
              }
            }
          }
        }
      }
    }
    .ng-arrow-wrapper {
      @include rtl {
        padding-left: $ng-select-value-padding-x-condensed;
      }
    }
  }

  .ng-dropdown-panel {
    &.ng-select-bottom {
      border-bottom-right-radius: $ng-select-border-radius-condensed;
      border-bottom-left-radius: $ng-select-border-radius-condensed;
      .ng-dropdown-panel-items {
        .ng-option {
          padding: $input-btn-padding-y-condensed $input-btn-padding-x-condensed;
          &:last-child {
            border-bottom-right-radius: $ng-select-border-radius-condensed;
            border-bottom-left-radius: $ng-select-border-radius-condensed;
          }
        }
        &.ng-select-top {
          border-top-right-radius: $ng-select-border-radius-condensed;
          border-top-left-radius: $ng-select-border-radius-condensed;
          .ng-dropdown-panel-items {
            .ng-option {
              &:first-child {
                border-top-right-radius: $ng-select-border-radius-condensed;
                border-top-left-radius: $ng-select-border-radius-condensed;
              }
            }
          }
        }
      }
    }
    .ng-dropdown-panel-items {
      .ng-option {
        padding-top: $input-btn-padding-y-condensed;
        padding-bottom: $input-btn-padding-y-condensed;
        &.ng-option-child {
          padding-left: 1.25rem;
          @include rtl {
            padding-right: 1.25rem;
          }
        }
        .ng-tag-label {
          padding-right: $ng-select-value-padding-x-condensed;
          @include rtl {
            padding-left: $ng-select-value-padding-x-condensed;
          }
        }
      }
    }
  }

  .scroll-host {
    border-bottom-right-radius: $ng-select-border-radius-condensed;
    border-bottom-left-radius: $ng-select-border-radius-condensed;
  }
}