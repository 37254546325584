// Condensed mode variables

//
// Grid columns
//

$grid-gutter-width-condensed:   1rem;



//
// Body
//



//
// Links
//



//
// Components
//



//
// Typography
//

$font-size-base-condensed: 1rem; //16px

$h1-font-size-condensed: $font-size-base-condensed * 1.75;
$h2-font-size-condensed: $font-size-base-condensed * 1.5;
$h3-font-size-condensed: $font-size-base-condensed * 1.375;
$h4-font-size-condensed: $font-size-base-condensed * 1.25;
$h5-font-size-condensed: $font-size-base-condensed * 1.125;
$h6-font-size-condensed: $font-size-base-condensed;

$display-font-sizes-condensed: (
  1: 3.25rem,
  2: 3rem,
  3: 2.75rem,
  4: 2.5rem,
  5: 2.25rem,
  6: 2rem,
);

//
// Tables
//

$table-cell-padding-y-condensed: 0.5rem;

//
// Buttons + Forms
//

$input-btn-padding-y-condensed:     0.4375rem;
$input-btn-padding-x-condensed:     0.75rem;
$input-btn-padding-y-lg-condensed:  0.5625rem;
$input-btn-padding-x-lg-condensed:  1.5rem;

//
// Buttons
//

$btn-padding-y-condensed:     $input-btn-padding-y-condensed;
$btn-padding-x-condensed:     1rem;
$btn-padding-y-lg-condensed:  $input-btn-padding-y-lg-condensed;
$btn-padding-x-lg-condensed:  $input-btn-padding-x-lg-condensed;

//
// Forms
//

$input-padding-y-condensed:   $input-btn-padding-y-condensed;
$input-padding-x-condensed:   $input-btn-padding-x-condensed;

$input-padding-y-lg-condensed: $input-btn-padding-y-lg-condensed;
$input-padding-x-lg-condensed: $input-btn-padding-x-lg-condensed;

$input-border-radius-condensed: .5rem;

$input-height-condensed:      add($input-line-height * 1em, add($input-padding-y-condensed * 2, $input-height-border, false));
$input-height-sm-condensed:   add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg-condensed:   add($input-line-height * 1em, add($input-padding-y-lg-condensed * 2, $input-height-border, false));

$form-select-padding-y-condensed: $input-padding-y-condensed;
$form-select-padding-x-condensed: $input-padding-x-condensed;

$form-select-bg-position-condensed:           right $form-select-padding-x-condensed center;


//
// Form validation
//



//
// Navs
//

$nav-link-padding-y-condensed:                .5rem;
$nav-link-padding-x-condensed:                1rem;

$sidebar-item-padding-y-condensed: .75rem;
$sidebar-item-padding-x-condensed: 1.25rem;
$sidebar-subnav-item-padding-y-condensed: .25rem;
$sidebar-subnav-item-padding-left-condensed: 4rem;
$sidebar-subnav-item-padding-right-condensed: .75rem;


//
// Navbar
//



//
// Dropdowns
//



//
// Pagination
//

$pagination-padding-y-condensed:              .438rem;
$pagination-padding-x-condensed:              1rem;
$pagination-padding-y-sm-condensed:           .282rem;
$pagination-padding-x-sm-condensed:           .75rem;
$pagination-padding-y-lg-condensed:           .5rem;
$pagination-padding-x-lg-condensed:           1.25rem;



//
// Placeholders
//



//
// Cards
//

$card-spacer-y-condensed:       0.75rem;
$card-spacer-x-condensed:       0.75rem;
$card-cap-padding-y-condensed:  $card-spacer-y-condensed;
$card-cap-padding-x-condensed:  $card-spacer-x-condensed;

//
// Accordion
//

$accordion-padding-y-condensed:           .75rem;
$accordion-padding-x-condensed:           .75rem;
$accordion-button-padding-y-condensed:    $accordion-padding-y-condensed;
$accordion-button-padding-x-condensed:    $accordion-padding-x-condensed;
$accordion-body-padding-x-condensed:      $accordion-padding-y-condensed;
$accordion-body-padding-y-condensed:      $accordion-padding-x-condensed;



//
// Tooltips
//



//
// Popovers
//



//
// Toasts
//

$toast-padding-x-condensed:                   .75rem;
$toast-padding-y-condensed:                   calc(.5rem - 1px);
$toast-left-border-condensed:                 2.75rem;



//
// Badges
//



//
// Modals
//

$modal-inner-padding-condensed:               $spacer;

$modal-header-padding-y-condensed:            $modal-inner-padding-condensed;
$modal-header-padding-x-condensed:            $modal-inner-padding-condensed;

$modal-header-padding-condensed:              $modal-header-padding-y-condensed $modal-header-padding-x-condensed;



//
// Alerts
//

$alert-padding-x-condensed: .75rem;
$alert-padding-y-condensed: calc(.5rem - 1px);



//
// Progress bars
//



//
// List group
//

$list-group-item-padding-y-condensed:         $spacer * .25;
$list-group-item-padding-x-condensed:         $spacer * .75;



//
// Breadcrumbs
//



//
// Spinners
//



//
// Close
//



//
// Offcanvas
//



//
// Code
//


//
// Third Party
//

$toastr-padding-y-condensed: calc(.5rem - 1px);
$toastr-padding-x-condensed: calc(.75rem - 1px);